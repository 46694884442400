import React from "react";
import Footer from "../components/layout/footer/Footer"
import ButtonGroup from "../components/ui/atom/button/button-group/ButtonGroup"
import Link from "../components/ui/atom/link/Link"
import { LogoTeaser } from "../components/ui/atom/logo/Logo"
import Tile from "../components/ui/atom/tile/Tile"

import * as Styles from "./prototype.module.scss";
import Button from "../components/ui/atom/button/Button";
import {Helmet} from "react-helmet";


const PrototypePage = () =>
    <>
        <Helmet>
            <title>Prototype | Karmalokal · Die Spendenplattform für Geld-, Sach- und Zeitspenden</title>
        </Helmet>

        <section className={ Styles.section }>
            <LogoTeaser logoClassName={ Styles.logo }/>

            <Tile className={ Styles.description }>
                <h4>
                    Interaktiver Prototyp
                </h4>

                <p>
                    Willkommen zum ersten interaktiven Prototypen von Karmalokal! Dieser Prototyp soll einen ersten Einblick
                    in die Mechanismen des Plattform&shy;konzeptes Karmalokal liefern. Bei Fragen und Anregungen können Sie mich gern über
                    <Link to={ "mailto:joseph@karmalokal.de" }>joseph@karmalokal.de</Link> kontaktieren.
                    Ich wünsche Ihnen viel Spaß und freue mich auf Ihr Feedback.
                </p>

                <p>
                    <em>
                        Herzlichst,<br/>
                        Joseph Ribbe
                    </em>
                </p>

                <ButtonGroup position={ "centered" }>
                    <Button
                        renderAs={ "a" }
                        href={ "https://www.figma.com/proto/tY5x2yyMuFqfWYeakOJjXF/Website?node-id=588%3A53&scaling=min-zoom&page-id=141%3A3696" }
                        target={ "_blank" }>
                        Prototyp öffnen
                    </Button>

                    <Button
                        primary={ false }
                        renderAs={ "a" }
                        href={ "/files/presentational/bachelor/thesis/thesis_karmalokal_joseph_ribbe.pdf" }
                        target={ "_blank" }
                    >
                        Bachelorthesis ansehen
                    </Button>
                </ButtonGroup>
            </Tile>
        </section>

        <Footer/>
    </>;

export default PrototypePage;
